<template>

  <div>
    <author-list-add-new
      :is-add-new-author-sidebar-active.sync="isAddNewAuthorSidebarActive"
      :author-data="authorData"
      @reset-data="resetAuthorData"
      @refetch-data="getAuthorData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="7"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="getAuthorData"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @keyup.enter="getAuthorData()"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refAuthorListTable"
        class="position-relative"
        :items="authorList"
        responsive
        :fields="tableColumns"
        primary-key="template_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="getAuthorData"
      >

        <!-- Column: Name -->
        <template #cell(name)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name:'author-history', params: { id: data.item.author_id} }"
            >
              {{ data.item.name }}
            </b-link>
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(company_name)="data">
          <div class="text-nowrap">
            <b-link
              :to="{ name:'author-company', params: { id: data.item.company_name} }"
            >
              {{ data.item.company_name }}
            </b-link>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div class="text-nowrap">
            {{ (data.item.status === 1) ? 'Active' : 'Non Active' }}
          </div>
        </template>

        <!-- Column: Updated At -->
        <template #cell(updated_at)="data">
          <div class="text-nowrap">
            {{ data.item.updated_at | formatDate }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="editData(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.status === 1"
              @click="confirmDel(data.item)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Block</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-else
              @click="confirmAct(data.item)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Unblock</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.total }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="dataMeta.total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onPageChange"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BDropdown, BDropdownItem, BPagination, BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthorListAddNew from './AuthorListAddNew.vue'

// Notification

export default {
  components: {
    AuthorListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,

    vSelect,
  },
  setup() {
    const toast = useToast()
    const isAddNewAuthorSidebarActive = ref(false)

    // Table Handlers
    const tableColumns = [
      { key: 'name', sortable: true },
      { key: 'company_name', sortable: true },
      { key: 'email', sortable: true },
      { key: 'phonenumber', sortable: true },
      { key: 'updated_at', label: 'Last Update', sortable: true },
      { key: 'status' },
      { key: 'actions' },
    ]

    const perPage = ref(25)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('author_id')
    const isSortDirDesc = ref(true)

    return {
      toast,
      // Sidebar
      isAddNewAuthorSidebarActive,

      tableColumns,
      perPage,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
    }
  },
  data() {
    return {
      authorList: [],
      dataMeta: '',
      authorData: {},
    }
  },
  created() {
    this.getAuthorData()
  },
  methods: {
    onPageChange(params) {
      window.scrollTo(0, 0)
      this.currentPage = params
      this.getAuthorData()
    },
    getAuthorData() {
      document.getElementById('loading-bg').style.display = 'block'
      this.authorData = {}

      const params = {
        length: this.perPage,
        filters: [],
      }
      if (this.searchQuery) {
        params.keyword = this.searchQuery
      }
      if (this.sortBy) {
        params.sort_by = [{
          column: this.sortBy,
          asc_desc: (this.isSortDirDesc) ? 'desc' : 'asc',
        }]
      }
      let target = '/authors'
      if (this.currentPage !== 1) {
        target += `?page=${this.currentPage}`
      }
      this.$http.post(target, params, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        },
      })
        .then(resp => {
          if (resp.status === 200) {
            document.getElementById('loading-bg').style.display = 'none'

            this.authorList = resp.data.data
            this.dataMeta = resp.data.meta
          }
        })
        .catch(() => {
          document.getElementById('loading-bg').style.display = 'none'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Customer list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    editData(data) {
      this.authorData = data
      this.isAddNewAuthorSidebarActive = true
    },
    resetAuthorData() {
      this.authorData = {}
    },
    confirmDel(data) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, block it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/author/delete', { author_id: data.author_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Customer has been blocked.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getAuthorData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error blocking customer',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    confirmAct(data) {
      this.$swal({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, activate User!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.$http.post('/author/activate', { author_id: data.author_id }, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            },
          })
            .then(resp => {
              if (resp.status === 200) {
                this.$swal({
                  icon: 'success',
                  title: 'Activated!',
                  text: 'User has been activated.',
                  showConfirmButton: false,
                })
                setTimeout(() => {
                  this.getAuthorData()
                  this.$swal.close()
                }, 1000)
              }
            })
            .catch(() => {
              this.toast({
                component: ToastificationContent,
                props: {
                  title: 'Error activating User',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
